define([
    'lodash',
    'coreUtils',
    'santa-components'
], function (_, coreUtils, santaComponents) {
    'use strict';
    const consts = coreUtils.mediaConsts;


    return {
        mixins: [santaComponents.mixins.animationsMixin],
        // API
        externalAPI: ['play', 'pause', 'stop', 'seek', 'setVolume', 'mute', 'unMute', 'setRate', 'setSrc'],

        /**
         * export API function
         * @param {String} command
         * @param {Array} params
         */
        mediaAPI(command, params = []) {
            const apiCall = this[command];
            if (apiCall && _.includes(this.externalAPI, command)) {
                apiCall(...params);
            }
        },
        getPosterImageComp(imageData, showMedia, extraProps) {
            const style = {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0
            };
            if (showMedia && this.props.compProp.animatePoster === 'fade') {
                this.animate(this.refs.poster, 'BaseFade', 1.6, 0, {opacity: 0, ease: 'Quad.easeOut'});
            } else {
                style.opacity = showMedia ? 0 : 1;
            }

            return this.createChildComponent(imageData, 'core.components.Image', consts.balataConsts.POSTER, _.assign({
                ref: consts.balataConsts.POSTER,
                key: style.backgroundColor ? 'posterImagePng' : 'posterImage',
                id: this.props.id + consts.balataConsts.POSTER,
                imageData,
                displayMode: this.props.compData.fittingType,
                alignType: this.props.compData.alignType,
                style
            }, extraProps));
        }
    };
});
