define([
    'lodash',
    'prop-types',
    'componentsCore',
    'coreUtils',
    'backgroundCommon/components/videogl'
], function (
    _,
    PropTypes,
    componentsCore,
    coreUtils,
    vgl) {
    'use strict';

    const {svgFilters, mediaConsts} = coreUtils;

    function createVglEffects(prop) {
        const effects = [
            vgl.effects.transparentVideo()
        ];

        if (('brightness' in prop) || ('contrast' in prop)) {
            const effect = vgl.effects.brightnessContrast();
            effects.push(effect);
            effects.brightnessContrast = effect;
        }

        if (prop.duotoneDark && prop.duotoneLight) {
            const effect = vgl.effects.duotone();
            effects.push(effect);
            effects.duotone = effect;
        }

        if (('hue' in prop) || ('saturation' in prop)) {
            const effect = vgl.effects.hueSaturation();
            effects.push(effect);
            effects.hueSaturation = effect;
        }

        return effects;
    }

    //TODO: handle removing effects
    // eslint-disable-next-line complexity
    function updateVglEffects(effects, prop) {
        // if we add a new effect the Vgl instance needs to re-init
        let needsInit = false;

        if (prop) {
            let bc = effects.brightnessContrast;
            let dt = effects.duotone;
            let hs = effects.hueSaturation;

            if (('brightness' in prop) || ('contrast' in prop)) {
                if (!bc) {
                    bc = vgl.effects.brightnessContrast();
                    effects.splice(1, 0, bc);
                    effects.brightnessContrast = bc;
                    needsInit = true;
                }

                if (prop.brightness || prop.brightness === 0) {
                    bc.brightness = prop.brightness;
                    bc.brightnessDsiabled = false;
                } else {
                    bc.brightnessDsiabled = true;
                }

                if (prop.contrast || prop.contrast === 0) {
                    bc.contrast = prop.contrast;
                    bc.contrastDisabled = false;
                } else {
                    bc.contrastDisabled = true;
                }
            } else if (bc) {
                bc.brightnessDsiabled = true;
                bc.contrastDisabled = true;
            }

            if (prop.duotoneDark && prop.duotoneLight) {
                if (!dt) {
                    dt = vgl.effects.duotone();
                    effects.splice(bc ? 2 : 1, 0, dt);
                    effects.duotone = dt;
                    needsInit = true;
                }

                dt.dark = hexToVec4(prop.duotoneDark);
                dt.light = hexToVec4(prop.duotoneLight);
                dt.disabled = false;
            } else if (dt) {
                dt.disabled = true;
            }

            if (('hue' in prop) || ('saturation' in prop)) {
                if (!hs) {
                    hs = vgl.effects.hueSaturation();
                    effects.push(hs);
                    effects.hueSaturation = hs;
                    needsInit = true;
                }

                if (prop.hue || prop.hue === 0) {
                    hs.hue = prop.hue;
                    hs.hueDisabled = false;
                } else {
                    hs.hueDisabled = true;
                }

                if (prop.saturation || prop.saturation === 0) {
                    hs.saturation = prop.saturation;
                    hs.saturationDisabled = false;
                } else {
                    hs.saturationDisabled = true;
                }
            } else if (hs) {
                hs.hueDisabled = true;
                hs.saturationDisabled = true;
            }
        }

        return needsInit;
    }

    function getVglEffects(prop) {
        const effects = createVglEffects(prop);
        updateVglEffects(effects, prop);

        return effects;
    }

    function getEffectDefaults(prop) {
        if (prop && prop.effectType) {
            return svgFilters.getProperties(prop.effectType)
                .reduce((acc, effect) => {
                    let values = {};

                    if (effect) {
                        if (effect.duotone) {
                            values.duotoneDark = effect.duotone.dark;
                            values.duotoneLight = effect.duotone.light;
                        } else {
                            values = effect;
                        }
                    }

                    Object.assign(acc, values);

                    return acc;
                }, {});
        }

        return {};
    }

    function filterEffectPropToVglEffects(prop) {
        if (prop) {
            const data = getEffectDefaults(prop);
            Object.assign(data, prop);

            return data;
        }

        return {};
    }

    function hexToVec4(hex) {
        if (typeof hex === 'string') {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

            return result ? [
                parseInt(result[1], 16) / 255,
                parseInt(result[2], 16) / 255,
                parseInt(result[3], 16) / 255,
                1
            ] : hex;
        }

        return hex;
    }

    return {
        displayName: 'webglMedia',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            playerStyle: PropTypes.object,
            notifyMediaState: PropTypes.func,
            getMediaSource: PropTypes.func,
            mediaWidth: PropTypes.number,
            mediaHeight: PropTypes.number,
            showMedia: PropTypes.bool,
            filterEffect: PropTypes.object
        },

        getDefaultProps() {
            return {
                playerStyle: {}
            };
        },
        getInitialState() {
            this.vgl = null;
            return {};
        },

        componentDidMount() {
            const target = this.refs.canvas;
            const effects = getVglEffects(filterEffectPropToVglEffects(this.props.filterEffect));

            try {
                this.vgl = new vgl.Vgl({target, effects});
            } catch (e) {
                this.props.notifyMediaState({
                    type: mediaConsts.eventTypes.ERROR,
                    error: mediaConsts.errorTypes.WEBGL_ERROR
                });
            }
        },

        componentWillReceiveProps(nextProps) {
            const media = nextProps.getMediaSource();

            if (media) {
                const width = nextProps.mediaWidth;
                const height = nextProps.mediaHeight;
                const source = {
                    type: 'video',
                    media,
                    width,
                    height
                };
                if (this.vgl) {
                    this.vgl.setSource(source);
                    this.vgl.play();
                }
            }

            const newEffects = nextProps.filterEffect;

            if (newEffects && this.vgl) {
                // mutates the instance's effects list *in-place*
                const needsInit = updateVglEffects(this.vgl.config.effects, filterEffectPropToVglEffects(newEffects));

                if (needsInit) {
                    this.vgl.init();
                }
            }
        },

        componentWillUnmount() {
            if (this.vgl) {
                this.vgl.destroy();
                this.vgl = null;
            }
        },

        getSkinProperties() {
            return {
                '': {
                    style: {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        overflow: 'hidden'
                    }
                },
                canvas: {
                    style: {
                        position: 'absolute',
                        opacity: this.props.showMedia ? 1 : 0
                    }
                }
            };
        }
    };
});
