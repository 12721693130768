define([
    'lodash',
    'prop-types',
    'componentsCore',
    'santa-components',
    'image-client-api',
    'backgroundCommon/components/bgImage',
    'backgroundCommon/components/html5Video',
    'backgroundCommon/components/webglMedia',
    'coreUtils'
], function (
    _,
    PropTypes,
    componentsCore,
    santaComponents,
    imageClientApi,
    bgImage,
    html5Video,
    webglMedia,
    coreUtils
) {
    'use strict';

    const {mediaConsts, svgFilters} = coreUtils;
    const {fittingTypes} = imageClientApi;
    const {
        POSTER,
        IMAGE,
        BG_IMAGE,
        VIDEO,
        WEBGL,
        IMAGE_BG_PARAMS,
        IMAGE_PARAMS,
        VIDEO_PARAMS,
        WEBGL_PARAMS,
        IFRAME_VIDEO_PARAMS
    } = mediaConsts.balataConsts;
    const descriptors = {
        image: {
            componentType: IMAGE_PARAMS.comp,
            skinPartData: {
                skin: IMAGE_PARAMS.skin,
                styleId: IMAGE_PARAMS.style
            }
        },
        bg_image: {
            componentType: IMAGE_BG_PARAMS.comp,
            skinPartData: {
                skin: IMAGE_BG_PARAMS.skin,
                styleId: IMAGE_BG_PARAMS.style
            }
        },
        poster: {
            componentType: IMAGE_PARAMS.comp,
            skinPartData: {
                skin: IMAGE_PARAMS.skin,
                styleId: IMAGE_PARAMS.style
            }
        },
        video: {
            componentType: VIDEO_PARAMS.comp,
            skinPartData: {
                skin: VIDEO_PARAMS.skin,
                styleId: VIDEO_PARAMS.style
            }

        },
        webgl: {
            componentType: WEBGL_PARAMS.comp,
            skinPartData: {
                skin: WEBGL_PARAMS.skin,
                styleId: WEBGL_PARAMS.style
            }
        },
        iframe: {
            componentType: IFRAME_VIDEO_PARAMS.comp,
            skinPartData: {
                skin: IFRAME_VIDEO_PARAMS.skin,
                styleId: IFRAME_VIDEO_PARAMS.style
            }
        }
    };

    /**
     * For tiled images we render an element with css background instead of image
     * If a filter is assigned and we need to render an SVG fallback for tiled images - render an image element
     * @param {object} props
     * @returns {boolean}
     */
    function shouldUseBgImage(props) {
        const {fittingType, cssFiltersSupported, filterEffect} = props;

        const isFilter = filterEffect && svgFilters.isFilterExists(filterEffect.effectType);
        const isTile = fittingType === fittingTypes.TILE;
        // tile && ((filter && !ie) || !filter)
        return isTile && ((isFilter && cssFiltersSupported) || !isFilter);
    }

    function getBgImageProps(props) {
        return {
            ref: IMAGE,
            id: props.id + IMAGE,
            filterEffect: props.filterEffect,
            style: _.pick(props.style, ['width', 'height']),
            mediaDimensions: props.mediaDimensions,
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            shouldRenderSrc: props.shouldRenderSrc,
            imageData: props.compData,
            fittingType: props.fittingType,
            alignType: props.alignType,
            'data-type': IMAGE_BG_PARAMS['data-type']
        };
    }

    function getImageProps(props) {
        return {
            ref: IMAGE,
            id: props.id + IMAGE,
            key: `img_${props.bgEffectName}`,
            style: {width: '100%', height: '100%', position: 'absolute', top: 0},
            containerWidth: _.get(props.mediaDimensions, 'width', 0),
            containerHeight: _.get(props.mediaDimensions, 'height', 0),
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            shouldRenderSrc: props.shouldRenderSrc,
            imageData: props.compData,
            filterEffect: props.filterEffect,
            displayMode: props.fittingType,
            fittingType: props.fittingType,
            alignType: props.alignType,
            'data-type': IMAGE_PARAMS['data-type']
        };
    }

    function getVideoProps(props, {registerMediaSource, notifyVideoVisibility, showMedia}) {
        const value = {
            ref: VIDEO,
            id: props.id + VIDEO,
            style: _.pick(props.style, ['width', 'height']),
            key: `vid_${props.compData.videoId}`,
            notifyMediaState: props.notifyMediaState,
            isPlayingAllowed: props.isPlayingAllowed,
            setMediaAPI: props.setMediaAPI,
            mediaQuality: props.mediaQuality,
            format: props.playbackFormat,
            config: props.playbackConfig,
            playbackUrl: props.playbackUrl,
            videoRenderParts: props.renderParts.media.video,
            compProp: props.compProp,
            keepVideoHidden: _.includes(props.compData.mediaFeatures, 'alpha'),
            notifyVideoVisibility,
            posterWidth: _.get(props.mediaDimensions, 'width', 0),
            posterHeight: _.get(props.mediaDimensions, 'height', 0),
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            shouldRenderSrc: props.shouldRenderSrc,
            filterEffect: props.filterEffect,
            showMedia
        };

        if (registerMediaSource) {
            value.registerMediaSource = registerMediaSource;
        }

        return value;
    }

    function getWebglProps(props, {getMediaSource, showMedia}) {
        const {width, height} = _.find(props.compData.qualities, {quality: props.mediaQuality});
        return {
            ref: WEBGL,
            id: props.id + WEBGL,
            key: `gl_${props.compData.videoId}`,
            getMediaSource,
            showMedia,
            notifyMediaState: props.notifyMediaState,
            mediaWidth: width,
            mediaHeight: height,
            playerStyle: props.playerStyle,
            filterEffect: props.filterEffect
        };
    }

    function getMediaType(props) {
        const {compData, enableVideo} = props;
        if (compData.type === 'Image') {
            return shouldUseBgImage(props) ? BG_IMAGE : IMAGE;
        }
        if (compData.type === 'WixVideo') {
            if (enableVideo) {
                if (_.includes(compData.mediaFeatures, 'alpha') && props.renderParts.media.video.length) {
                    return WEBGL;
                }
                return VIDEO;
            }
            return POSTER;
        }
    }


    /**
     * @class components.bgMedia
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'bgMedia',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.defaults(
            {
                id: PropTypes.string.isRequired,
                compData: PropTypes.object.isRequired,
                compProp: PropTypes.object,
                alignType: PropTypes.string,
                fittingType: PropTypes.string,
                mediaTransforms: PropTypes.object,

                filterEffect: PropTypes.object,
                bgEffectName: PropTypes.string,

                style: PropTypes.object.isRequired,
                playerStyle: PropTypes.object,

                isPlayingAllowed: PropTypes.bool,
                enableVideo: PropTypes.bool,
                mediaQuality: PropTypes.string,
                renderParts: PropTypes.object,
                notifyMediaState: PropTypes.func,
                setMediaAPI: PropTypes.func,

                cssFiltersSupported: santaComponents.santaTypesDefinitions.BrowserFlags.cssFiltersSupported.isRequired,
                renderFixedPositionBackgrounds: santaComponents.santaTypesDefinitions.RenderFlags.renderFixedPositionBackgrounds
            },
            santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(bgImage),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(html5Video),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(webglMedia)
        ),

        getInitialState() {
            return {
                mediaSource: null,
                showMedia: false
            };
        },

        /**
         * Get the Wix types of the the component to render (id, style, data, etc.)
         * @returns {object}
         */
        getMediaComponentsDescriptors() {
            const componentsDescriptors = [];
            const {compData} = this.props;
            const type = getMediaType(this.props);
            switch (type) {
                case IMAGE: {
                    const extraProps = getImageProps(this.props);
                    const imageDesc = _.assign({}, descriptors.image, {extraProps, compData});
                    componentsDescriptors.push(imageDesc);
                    break;
                }
                case BG_IMAGE: {
                    const extraProps = getBgImageProps(this.props);
                    const imageDesc = _.assign({}, descriptors.bg_image, {extraProps, compData});
                    componentsDescriptors.push(imageDesc);
                    break;
                }
                case VIDEO: {
                    const localProps = {
                        showMedia: this.state.showMedia,
                        notifyVideoVisibility: this.notifyVideoVisibility
                    };
                    const extraProps = getVideoProps(this.props, localProps);
                    const videoDesc = _.assign({}, descriptors.video, {extraProps, compData});
                    componentsDescriptors.push(videoDesc);
                    break;
                }
                case WEBGL: {
                    const webglLocalProps = {
                        showMedia: this.state.showMedia,
                        getMediaSource: this.getMediaSource
                    };
                    const webglExtraProps = getWebglProps(this.props, webglLocalProps);
                    const webglDesc = _.assign({}, descriptors.webgl, {extraProps: webglExtraProps, compData});
                    componentsDescriptors.push(webglDesc);

                    const videoLocalProps = {
                        showMedia: this.state.showMedia,
                        notifyVideoVisibility: this.notifyVideoVisibility,
                        registerMediaSource: this.registerMediaSource
                    };
                    const videoExtraProps = getVideoProps(this.props, videoLocalProps);
                    const videoDesc = _.assign({}, descriptors.video, {extraProps: videoExtraProps, compData});
                    componentsDescriptors.push(videoDesc);

                    break;
                }
                case POSTER: {
                    const extraProps = getImageProps(this.props);
                    extraProps.imageData = compData.posterImageRef;
                    const posterDesc = _.assign({}, descriptors.poster, {extraProps, compData: compData.posterImageRef});
                    componentsDescriptors.push(posterDesc);
                    break;
                }
            }

            return componentsDescriptors;
        },
        /**
         * Get the component to render as media
         * @returns {ReactCompositeComponent}
         */
        getMediaComponents() {
            const componentDescriptors = this.getMediaComponentsDescriptors();
            return _.map(componentDescriptors, descriptor => {
                const {compData, componentType, skinPartData, extraProps} = descriptor;
                return this.createChildComponent(compData, componentType, skinPartData, extraProps);
            });
        },

        getMediaSource() {
            return this.state.mediaSource;
        },

        registerMediaSource(mediaSource) {
            if (mediaSource !== this.state.mediaSource) {
                this.setState({mediaSource});
            }
        },

        notifyVideoVisibility(showMedia) {
            if (showMedia !== this.state.showMedia) {
                this.setState({showMedia});
            }
        },

        getSkinProperties() {
            const children = this.getMediaComponents();

            // Change key when moving between editor and preview to unmount the media (instead of clearing animations and media info)
            const key = `media_${this.props.isPlayingAllowed ? 'playback' : 'no_playback'}_${this.props.enableVideo ? 'video' : 'no_video'}`;

            // For Animation baseClear. do not delete
            const transformDataAttrs = _.mapKeys(this.props.mediaTransforms, (value, attr) => `data-${attr}`);

            return {
                '': _.assign({
                    key,
                    children,
                    style: this.props.style,
                    /**
                     * For QA Automation
                     * https://jira.wixpress.com/browse/SE-11582
                     * https://jira.wixpress.com/browse/SE-15126
                     * https://jira.wixpress.com/browse/CLNT-6534
                     */
                    'data-effect': this.props.bgEffectName || 'none',
                    'data-fitting': this.props.fittingType,
                    'data-align': this.props.alignType
                }, transformDataAttrs)
            };
        }
    };
});
